/* eslint-disable @typescript-eslint/no-duplicate-type-constituents */
export type SocialPlatformMetrics = FacebookMetrics | InstagramMetrics | TwitterMetrics | LinkedInMetrics | YouTubeMetrics | TikTokMetrics;

export interface BaseMetrics {
  numImpressions: number;
  numLikes: number;
  numClicks: number;
  numComments: number;
  numShares: number;
  numReach: number;
  numViews: number;
}

export type FacebookMetrics = BaseMetrics;

export interface InstagramMetrics extends BaseMetrics {
  numSaves: number;
}

export interface TwitterMetrics extends BaseMetrics {
  numRetweets: number;
  numReplies: number;
  numQuoted: number;
  numBookmarked: number;
}

export interface LinkedInMetrics extends BaseMetrics {
  numEngagement: number;
  numUniqueImpressions: number;
}

export interface YouTubeMetrics extends BaseMetrics {
  numEstimatedMinutesWatched: number;
  numAverageViewDuration: number;
  numDislikes: number;
}

export interface PinterestMetrics extends BaseMetrics {
  numReactions: number;
  numClickthrough: number;
  numSaves: number;
}

export type TikTokMetrics = BaseMetrics;

export const metricsBase: (keyof BaseMetrics)[] = ['numLikes', 'numComments', 'numReach', 'numImpressions', 'numShares'];
export const metricsInstagramPost: (keyof InstagramMetrics)[] = [...metricsBase, 'numSaves'];
export const metricsInstagramVideo: (keyof InstagramMetrics)[] = [...metricsBase, 'numSaves'];
export const metricsTwitter: (keyof TwitterMetrics)[] = ['numLikes', 'numReplies', 'numRetweets', 'numQuoted', 'numBookmarked'];
export const metricsLinkedInPage: (keyof LinkedInMetrics)[] = [...metricsBase, 'numEngagement'];
export const metricsYouTube: (keyof YouTubeMetrics)[] = [...metricsBase, 'numDislikes'];
export const metricsPinterest: (keyof PinterestMetrics)[] = [...metricsBase, 'numReactions', 'numSaves'];
export const metricsFacebook: (keyof FacebookMetrics)[] = [...metricsBase];
export const metricsTikTok: (keyof TikTokMetrics)[] = ['numLikes', 'numComments', 'numShares', 'numViews'];

export type AllMetricKeys =
  | undefined
  | keyof BaseMetrics
  | keyof InstagramMetrics
  | keyof TwitterMetrics
  | keyof LinkedInMetrics
  | keyof YouTubeMetrics
  | keyof PinterestMetrics
  | keyof FacebookMetrics
  | keyof TikTokMetrics;

export interface MetricLabels {
  numImpressions: string;
  numLikes: string;
  numClicks: string;
  numComments: string;
  numShares: string;
  numReach: string;
  numViews: string;
  numSaves: string;
  numRetweets: string;
  numReplies: string;
  numQuoted: string;
  numBookmarked: string;
  numEngagement: string;
  numDislikes: string;
  numReactions: string;
  // TODO: re-enable later when UX is simplified
  // numUniqueImpressions: string;
  // numEstimatedMinutesWatched: string;
  // numAverageViewDuration: string;
  // numClickthrough: string;
}

export const getReadableMetricByKey = (key: keyof MetricLabels, isFacebook: boolean): string => {
  const labels: MetricLabels = {
    numImpressions: window.i18next.t('pmwjs_impressions'),
    numLikes: isFacebook ? window.i18next.t('pmwjs_reactions') : window.i18next.t('pmwjs_likes'),
    numClicks: window.i18next.t('pmwjs_clicks'),
    numComments: window.i18next.t('pmwjs_comments'),
    numShares: window.i18next.t('pmwjs_shares'),
    numReach: window.i18next.t('pmwjs_reach'),
    numViews: window.i18next.t('pmwjs_views'),
    numSaves: window.i18next.t('pmwjs_saves'),
    numRetweets: window.i18next.t('pmwjs_retweets'),
    numReplies: window.i18next.t('pmwjs_replies'),
    numQuoted: window.i18next.t('pmwjs_quotes'),
    numBookmarked: window.i18next.t('pmwjs_bookmarks'),
    numEngagement: window.i18next.t('pmwjs_engagement_rate'),
    numDislikes: window.i18next.t('pmwjs_dislikes'),
    numReactions: window.i18next.t('pmwjs_reaction'),
    // numUniqueImpressions: `${window.i18next.t('pmwjs_unique_impressions')}`,
    // numEstimatedMinutesWatched: `${window.i18next.t('pmwjs_estimated_minutes_watched')}`,
    // numAverageViewDuration: `${window.i18next.t('pmwjs_average_view_duration')}`,
    // numClickthrough: 'CTR',
  };

  return labels[key];
};

export const getMetricIconByKey = (key: keyof MetricLabels): string => {
  const labels: MetricLabels = {
    numImpressions: `icon-teamwork`,
    numLikes: `icon-thumbs-up`,
    numClicks: `icon-thumbs-up`,
    numComments: `icon-message`,
    numShares: `icon-share-external`,
    numReach: `icon-megaphone`,
    numViews: `icon-eye`,
    numSaves: `icon-bookmark`,
    numRetweets: `icon-retweet`,
    numReplies: `icon-message`,
    numQuoted: `icon-message`,
    numBookmarked: `icon-bookmark`,
    numEngagement: `icon-engagement`,
    numDislikes: `icon-thumbs-down`,
    numReactions: `icon-thumbs-up`,
    // numUniqueImpressions: `icon-bookmark`, // uncomment these if needed
    // numEstimatedMinutesWatched: `icon-bookmark`, // uncomment these if needed
    // numAverageViewDuration: `icon-bookmark`, // uncomment these if needed
    // numClickthrough: `icon-bookmark`, // uncomment these if needed
  };

  return labels[key];
};

const getTooltipTextForLikes = (isDefaultSelection: boolean, isFacebook: boolean): string => {
  if (isDefaultSelection) {
    return window.i18next.t('pmwjs_likes_tooltip_text_default_level');
  }

  if (isFacebook) {
    return window.i18next.t('pmwjs_reactions_tooltip_text');
  }

  return window.i18next.t('pmwjs_likes_tooltip_text');
};

export const getMetricIconTooltipByKey = (key: keyof MetricLabels, isDefaultSelection: boolean, isFacebook: boolean): string => {
  const labels: MetricLabels = {
    numImpressions: isDefaultSelection ? window.i18next.t('pmwjs_impressions_tooltip_text_default_level') : window.i18next.t('pmwjs_impressions_tooltip_text'),
    numLikes: getTooltipTextForLikes(isDefaultSelection, isFacebook),
    numClicks: window.i18next.t('pmwjs_clicks_tooltip_text'),
    numComments: isDefaultSelection ? window.i18next.t('pmwjs_comments_tooltip_text_default_level') : window.i18next.t('pmwjs_comments_tooltip_text'),
    numShares: isDefaultSelection ? window.i18next.t('pmwjs_shares_tooltip_text_default_level') : window.i18next.t('pmwjs_shares_tooltip_text'),
    numReach: isDefaultSelection ? window.i18next.t('pmwjs_reach_tooltip_text_default_level') : window.i18next.t('pmwjs_reach_tooltip_text'),
    numViews: window.i18next.t('pmwjs_views_tooltip_text'),
    numSaves: window.i18next.t('pmwjs_saves_tooltip_text'),
    numRetweets: window.i18next.t('pmwjs_retweets_tooltip_text'),
    numReplies: window.i18next.t('pmwjs_replies_tooltip_text'),
    numQuoted: window.i18next.t('pmwjs_quotes_tooltip_text'),
    numBookmarked: window.i18next.t('pmwjs_bookmarks_tooltip_text'),
    numEngagement: window.i18next.t('pmwjs_engagement_tooltip_text'),
    numDislikes: window.i18next.t('pmwjs_dislikes_tooltip_text'),
    numReactions: window.i18next.t('pmwjs_reactions_tooltip_text'),
    // numUniqueImpressions: `${window.i18next.t('pmwjs_unique_impressions_tooltip_text')}`,
    // numEstimatedMinutesWatched: `${window.i18next.t('pmwjs_estimated_minutes_watched_tooltip_text')}`,
    // numAverageViewDuration: `${window.i18next.t('pmwjs_average_view_tooltip_text')}`,
    // numClickthrough: `${window.i18next.t('pmwjs_clickthrough_tooltip_text')}`,
  };

  return labels[key];
};

export const getYouTubeTooltipByKey = (key: keyof MetricLabels): string => {
  const labels: MetricLabels = {
    numBookmarked: '',
    numEngagement: '',
    numQuoted: '',
    numReactions: '',
    numReplies: '',
    numRetweets: '',
    numImpressions: window.i18next.t('pmwjs_video_impressions_tooltip_text'),
    numLikes: window.i18next.t('pmwjs_video_likes_tooltip_text'),
    numClicks: window.i18next.t('pmwjs_video_clicks_tooltip_text'),
    numViews: window.i18next.t('pmwjs_video_views_tooltip_text'),
    numComments: window.i18next.t('pmwjs_video_comments_tooltip_text'),
    numShares: window.i18next.t('pmwjs_video_shares_tooltip_text'),
    numReach: window.i18next.t('pmwjs_video_reach_tooltip_text'),
    numSaves: window.i18next.t('pmwjs_video_saves_tooltip_text'),
    numDislikes: window.i18next.t('pmwjs_dislikes_tooltip_text'),
  };

  return labels[key];
};
